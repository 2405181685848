import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "play-and-earn"
    }}>{`Play and Earn`}</h1>
    <hr></hr>
    <p>{`Play to Earn has recently become a game category. The games in this category will try to persuade their players to invest and reward them somehow. Some say this kind of games are ponzi games. We have no comments on them but we do not categorize Farming Season as Play to Earn but Play and Earn`}</p>
    <p>{`Not sure it has been used elsewhere or not. By our meaning, a Play and Earn game focuses on gaming elements. Its players should enjoy the game just like when they play traditional games and may earn some as a reward, not return of investment`}</p>
    <blockquote>
      <p parentName="blockquote">{`Play to Earn might be a financial app in disguise while Play and Earn should be a game that is fun to play`}</p>
    </blockquote>
    <hr></hr>
    <h2 {...{
      "id": "play-and-earn-model"
    }}>{`Play and Earn Model`}</h2>
    <p>{`Model of earning and spending CLC token inside the game.`}</p>
    <h3 {...{
      "id": "earn-"
    }}>{`Earn :`}</h3>
    <ul>
      <li parentName="ul">{`Complete the quests.`}</li>
      <li parentName="ul">{`Sell special products to in-game exchange market.`}</li>
      <li parentName="ul">{`Sell characters in the marketplace.`}</li>
    </ul>
    <h3 {...{
      "id": "spend-"
    }}>{`Spend :`}</h3>
    <ul>
      <li parentName="ul">{`Buy a character gacha box.`}</li>
      <li parentName="ul">{`Buy characters from the marketplace.`}</li>
      <li parentName="ul">{`Buy farmlands.`}</li>
      <li parentName="ul">{`Fees`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "deposit--withdraw"
    }}>{`Deposit & Withdraw`}</h2>
    <p><img alt="wallet" src={require("./public/images/deposit-withdraw.png")} /></p>
    <h3 {...{
      "id": "deposit"
    }}>{`Deposit`}</h3>
    <p>{`You can deposit CLC to the game from wallet in the dashboard. You may need in-game CLC to pay for various fees. `}</p>
    <p>{`Depositing conditions :`}</p>
    <ul>
      <li parentName="ul">{`Minimum deposit is 200 CLC`}</li>
      <li parentName="ul">{`Maximum deposit is 2500 CLC`}</li>
    </ul>
    <h3 {...{
      "id": "withdraw"
    }}>{`Withdraw`}</h3>
    <p>{`You can withdraw CLC from the game to your wallet. `}</p>
    <p>{`Withdrawal conditions :`}</p>
    <ul>
      <li parentName="ul">{`Minimum withdraw is 200 CLC`}</li>
      <li parentName="ul">{`Maximum withdraw is 2500 CLC`}</li>
    </ul>
    <p>{`Withdrawal also has cooldown period. The next withdraw will be available within 20 - 24 hours randomly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      